import "./src/base/styles/main.scss";

function initGTM() {
  if (window.isGTMLoaded) {
    return;
  }

  window.isGTMLoaded = true;

  const script = document.createElement("script");
  script.type = "text/javascript";
  script.defer = true;
  script.src =
    "https://www.googletagmanager.com/gtm.js?id=" + process.env.GATSBY_GTM_ID;
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
  };

  document.head.appendChild(script);
}

export const onClientEntry = () => {
  if (typeof window !== "undefined") {
    initGTM();
  }
};
